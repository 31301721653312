<template>
    <div class="box">
        <h2>Crefo Prüfungskonfigurationen</h2>
        <div class="body">
            <form @submit.prevent="doSearch()" v-show="crefoChecks.total > 0">
                <div class="form-group form-float with-icon no-error">
                    <input v-model="searchTmp" id="search" placeholder=" " type="text" />
                    <label for="search">Suche</label>
                    <i @click="doSearch()" class="fal fa-search"></i>
                </div>
            </form>
            <table v-show="crefoChecks.total > 0">
                <thead>
                    <th>Bezeichner</th>
                </thead>
                <tbody>
                    <tr class="clickable-row" v-for="config in crefoChecks.results" v-bind:key="config.id">
                        <td @click="load(config.id)">{{ config.label }}</td>
                        <td style="width: 2rem">
                            <dropdown class="right">
                                <template slot="trigger"
                                    ><button class="fab btn flat"><i class="fal fa-ellipsis-v"></i></button
                                ></template>
                                <template slot="menu">
                                    <ul>
                                        <li>
                                            <router-link :to="{ name: 'crefo_config_edit', params: { id: config.id } }"><i class="fal fa-pencil"></i> Edit</router-link>
                                        </li>
                                        <li>
                                            <a href="#" @click.prevent="deleteConfig(config.id)"><i class="fal fa-trash-alt"></i> Delete</a>
                                        </li>
                                    </ul>
                                </template>
                            </dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
            <paging :page="this.page" :page-size="this.pageSize" :total="this.crefoChecks.total" @select-page="page = $event" />

            <div v-show="crefoChecks.total == 0" class="info-box">
                Es sind noch keine Konfigurationen vorhanden
            </div>
        </div>

        <button @click="create()" class="fab size-l br secondary"><i class="fal fa-plus"></i></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchTmp: "",
            page: 1,
            pageSize: 25,
            search: "",
            crefoChecks: {
                total: 0,
                results: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        doSearch() {
            this.search = this.searchTmp;
        },
        create() {
            this.$router.push({ name: "crefo_config_create" });
        },
        load(id) {
            this.$router.push({ name: "crefo_config_edit", params: { id: id } });
        },
        deleteConfig(id) {
            this.$snotify.confirm("Config dauerhaft löschen?", "Letzte Möglichkeit", {
                closeOnClick: false,
                position: "centerCenter",
                buttons: [
                    {
                        text: "Ja",
                        action: toast => {
                            this.performDelete(id);
                            this.$snotify.remove(toast.id);
                        }
                    },
                    {
                        text: "Nein"
                    }
                ]
            });
        },
        performDelete(id) {
            this.$api.delete("/crefo/check/" + id).then(
                () => {
                    // Event deleted
                    this.$snotify.success("Prüfung gelöscht");
                    this.fetchData();
                },
                () => {
                    // Delete failed
                    this.$snotify.error("Prüfung löschen fehlgeschlagen");
                }
            );
        },
        fetchData() {
            let params = {
                page: this.page,
                pageSize: this.pageSize,
                q: this.search
            };

            this.$api.get("/crefo/checks", { params: params }).then(
                response => {
                    this.crefoChecks = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Prüfungen");
                }
            );
        }
    }
};
</script>
